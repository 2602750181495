.sprite-sb-large { background: url('./assets/sprites/sb_large.png') no-repeat top left; width: 138px; height: 138px;  } 
.sprite-sb-large.sb_0 { background-position: 0 0; width: 92px; } 
.sprite-sb-large.sb_1 { background-position: -102px 0; width: 92px; } 
.sprite-sb-large.sb_2 { background-position: -204px 0; width: 92px; } 
.sprite-sb-large.sb_3 { background-position: -306px 0; width: 92px; } 
.sprite-sb-large.sb_4 { background-position: -408px 0; width: 92px; } 
.sprite-sb-large.sb_5 { background-position: -510px 0; width: 92px; } 
.sprite-sb-large.sb_6 { background-position: -612px 0; width: 92px; } 
.sprite-sb-large.sb_7 { background-position: -714px 0; width: 92px; } 
.sprite-sb-large.sb_8 { background-position: -816px 0; width: 92px; } 
.sprite-sb-large.sb_9 { background-position: -918px 0; width: 92px; } 
.sprite-sb-large.sb_A { background-position: -1020px 0; } 
.sprite-sb-large.sb_B { background-position: -1168px 0; width: 104px; } 
.sprite-sb-large.sb_C { background-position: -1282px 0; } 
.sprite-sb-large.sb_D { background-position: -1430px 0; } 
.sprite-sb-large.sb_E { background-position: -1578px 0; } 
.sprite-sb-large.sb_F { background-position: -1726px 0; } 
.sprite-sb-large.sb_G { background-position: -1874px 0; height: 207px; } 
.sprite-sb-large.sb_H { background-position: -2022px 0; height: 207px; } 
.sprite-sb-large.sb_I { background-position: -2170px 0; width: 69px; } 
.sprite-sb-large.sb_J { background-position: -2249px 0; } 
.sprite-sb-large.sb_K { background-position: -2397px 0; } 
.sprite-sb-large.sb_L { background-position: -2545px 0; width: 137px; } 
.sprite-sb-large.sb_M { background-position: -2692px 0; width: 207px; } 
.sprite-sb-large.sb_N { background-position: -2909px 0; } 
.sprite-sb-large.sb_O { background-position: -3057px 0; } 
.sprite-sb-large.sb_P { background-position: -3205px 0; width: 104px; } 
.sprite-sb-large.sb_Q { background-position: -3319px 0; height: 207px; } 
.sprite-sb-large.sb_R { background-position: -3467px 0; } 
.sprite-sb-large.sb_S { background-position: -3615px 0; width: 207px; } 
.sprite-sb-large.sb_T { background-position: -3832px 0; width: 207px; } 
.sprite-sb-large.sb_U { background-position: -4049px 0; } 
.sprite-sb-large.sb_V { background-position: -4197px 0; width: 190px; height: 144px; } 
.sprite-sb-large.sb_W { background-position: -4397px 0; width: 235px; height: 144px; } 
.sprite-sb-large.sb_Y { background-position: -4642px 0; } 
.sprite-sb-large.sb_X { background-position: -4790px 0; } 
.sprite-sb-large.sb_Z { background-position: -4938px 0; } 



.sprite-sb-medium { background: url('./assets/sprites/sb_medium.png') no-repeat top left; width: 75px; height: 75px;  } 
.sprite-sb-medium.sb_0 { background-position: 0 0; width: 50px; } 
.sprite-sb-medium.sb_1 { background-position: -60px 0; width: 50px; } 
.sprite-sb-medium.sb_2 { background-position: -120px 0; width: 50px; } 
.sprite-sb-medium.sb_3 { background-position: -180px 0; width: 50px; } 
.sprite-sb-medium.sb_5 { background-position: -240px 0; width: 50px; } 
.sprite-sb-medium.sb_4 { background-position: -300px 0; width: 50px; } 
.sprite-sb-medium.sb_6 { background-position: -360px 0; width: 50px; } 
.sprite-sb-medium.sb_7 { background-position: -420px 0; width: 51px; } 
.sprite-sb-medium.sb_8 { background-position: -481px 0; width: 50px; } 
.sprite-sb-medium.sb_9 { background-position: -541px 0; width: 50px; } 
.sprite-sb-medium.sb_A { background-position: -601px 0; } 
.sprite-sb-medium.sb_B { background-position: -686px 0; width: 57px; } 
.sprite-sb-medium.sb_C { background-position: -753px 0; height: 76px; } 
.sprite-sb-medium.sb_D { background-position: -838px 0; height: 76px; } 
.sprite-sb-medium.sb_E { background-position: -923px 0; } 
.sprite-sb-medium.sb_F { background-position: -1008px 0; height: 76px; } 
.sprite-sb-medium.sb_G { background-position: -1093px 0; height: 113px; } 
.sprite-sb-medium.sb_H { background-position: -1178px 0; height: 113px; } 
.sprite-sb-medium.sb_I { background-position: -1263px 0; width: 38px; } 
.sprite-sb-medium.sb_J { background-position: -1311px 0; } 
.sprite-sb-medium.sb_K { background-position: -1396px 0; } 
.sprite-sb-medium.sb_L { background-position: -1481px 0; } 
.sprite-sb-medium.sb_M { background-position: -1566px 0; width: 113px; } 
.sprite-sb-medium.sb_N { background-position: -1689px 0; } 
.sprite-sb-medium.sb_O { background-position: -1774px 0; } 
.sprite-sb-medium.sb_P { background-position: -1859px 0; width: 57px; } 
.sprite-sb-medium.sb_Q { background-position: -1926px 0; height: 113px; } 
.sprite-sb-medium.sb_R { background-position: -2849px 0; } 
.sprite-sb-medium.sb_S { background-position: -2011px 0; width: 113px; } 
.sprite-sb-medium.sb_T { background-position: -2333px 0; width: 113px; } 
.sprite-sb-medium.sb_U { background-position: -2134px 0; } 
.sprite-sb-medium.sb_V { background-position: -2219px 0; width: 104px; height: 78px; } 
.sprite-sb-medium.sb_W { background-position: -2456px 0; width: 128px; height: 78px; } 
.sprite-sb-medium.sb_X { background-position: -2764px 0; } 
.sprite-sb-medium.sb_Y { background-position: -2594px 0; } 
.sprite-sb-medium.sb_Z { background-position: -2679px 0; } 

.sprite-sb-small { background: url('./assets/sprites/sb_small.png') no-repeat top left; width: 41px; height: 41px;  } 
.sprite-sb-small.sb_9 { background-position: 0 0; width: 27px; } 
.sprite-sb-small.sb_A { background-position: -37px 0; } 
.sprite-sb-small.sb_7 { background-position: -88px 0; width: 28px; } 
.sprite-sb-small.sb_2 { background-position: -126px 0; width: 27px; } 
.sprite-sb-small.sb_3 { background-position: -163px 0; width: 27px; } 
.sprite-sb-small.sb_1 { background-position: -200px 0; width: 27px; } 
.sprite-sb-small.sb_4 { background-position: -237px 0; width: 27px; } 
.sprite-sb-small.sb_0 { background-position: -274px 0; width: 27px; } 
.sprite-sb-small.sb_6 { background-position: -311px 0; width: 27px; } 
.sprite-sb-small.sb_5 { background-position: -348px 0; width: 27px; } 
.sprite-sb-small.sb_8 { background-position: -385px 0; width: 27px; } 
.sprite-sb-small.sb_B { background-position: -422px 0; width: 31px; } 
.sprite-sb-small.sb_F { background-position: -463px 0; } 
.sprite-sb-small.sb_E { background-position: -514px 0; } 
.sprite-sb-small.sb_D { background-position: -565px 0; } 
.sprite-sb-small.sb_C { background-position: -616px 0; } 
.sprite-sb-small.sb_H { background-position: -667px 0; height: 62px; } 
.sprite-sb-small.sb_O { background-position: -718px 0; } 
.sprite-sb-small.sb_Q { background-position: -769px 0; height: 62px; } 
.sprite-sb-small.sb_N { background-position: -820px 0; } 
.sprite-sb-small.sb_V { background-position: -871px 0; width: 56px; height: 43px; } 
.sprite-sb-small.sb_W { background-position: -937px 0; width: 69px; height: 43px; } 
.sprite-sb-small.sb_Z { background-position: -1016px 0; } 
.sprite-sb-small.sb_J { background-position: -1067px 0; } 
.sprite-sb-small.sb_G { background-position: -1118px 0; height: 62px; } 
.sprite-sb-small.sb_I { background-position: -1169px 0; width: 21px; } 
.sprite-sb-small.sb_L { background-position: -1200px 0; } 
.sprite-sb-small.sb_K { background-position: -1251px 0; } 
.sprite-sb-small.sb_S { background-position: -1302px 0; width: 61px; } 
.sprite-sb-small.sb_P { background-position: -1373px 0; width: 31px; } 
.sprite-sb-small.sb_R { background-position: -1414px 0; } 
.sprite-sb-small.sb_T { background-position: -1465px 0; width: 61px; } 
.sprite-sb-small.sb_U { background-position: -1536px 0; } 
.sprite-sb-small.sb_X { background-position: -1587px 0; } 
.sprite-sb-small.sb_Y { background-position: -1638px 0; } 
.sprite-sb-small.sb_M { background-position: -1689px 0; width: 61px; } 


.sprite-sb-extraSmall { background: url('./assets/sprites/sb_extra_small.png') no-repeat top left; width: 25px; height: 25px;  } 
.sprite-sb-extraSmall.sb_0 { background-position: 0 0; width: 18px; } 
.sprite-sb-extraSmall.sb_1 { background-position: -28px 0; width: 17px; } 
.sprite-sb-extraSmall.sb_2 { background-position: -55px 0; width: 17px; } 
.sprite-sb-extraSmall.sb_3 { background-position: -82px 0; width: 17px; } 
.sprite-sb-extraSmall.sb_4 { background-position: -109px 0; width: 17px; } 
.sprite-sb-extraSmall.sb_6 { background-position: -136px 0; width: 17px; } 
.sprite-sb-extraSmall.sb_5 { background-position: -163px 0; width: 17px; } 
.sprite-sb-extraSmall.sb_7 { background-position: -190px 0; width: 17px; } 
.sprite-sb-extraSmall.sb_8 { background-position: -217px 0; width: 17px; } 
.sprite-sb-extraSmall.sb_9 { background-position: -244px 0; width: 17px; } 
.sprite-sb-extraSmall.sb_A { background-position: -271px 0; } 
.sprite-sb-extraSmall.sb_B { background-position: -306px 0; width: 19px; } 
.sprite-sb-extraSmall.sb_C { background-position: -335px 0; width: 26px; } 
.sprite-sb-extraSmall.sb_D { background-position: -371px 0; } 
.sprite-sb-extraSmall.sb_F { background-position: -406px 0; width: 26px; } 
.sprite-sb-extraSmall.sb_G { background-position: -442px 0; height: 37px; } 
.sprite-sb-extraSmall.sb_E { background-position: -477px 0; } 
.sprite-sb-extraSmall.sb_H { background-position: -512px 0; width: 26px; height: 37px; } 
.sprite-sb-extraSmall.sb_I { background-position: -548px 0; width: 13px; } 
.sprite-sb-extraSmall.sb_J { background-position: -571px 0; } 
.sprite-sb-extraSmall.sb_M { background-position: -606px 0; width: 37px; } 
.sprite-sb-extraSmall.sb_N { background-position: -653px 0; width: 26px; } 
.sprite-sb-extraSmall.sb_O { background-position: -689px 0; } 
.sprite-sb-extraSmall.sb_P { background-position: -724px 0; width: 19px; } 
.sprite-sb-extraSmall.sb_Q { background-position: -753px 0; width: 26px; height: 37px; } 
.sprite-sb-extraSmall.sb_R { background-position: -789px 0; } 
.sprite-sb-extraSmall.sb_S { background-position: -824px 0; width: 37px; } 
.sprite-sb-extraSmall.sb_T { background-position: -871px 0; width: 38px; } 
.sprite-sb-extraSmall.sb_U { background-position: -919px 0; width: 26px; } 
.sprite-sb-extraSmall.sb_V { background-position: -955px 0; width: 34px; height: 26px; } 
.sprite-sb-extraSmall.sb_W { background-position: -999px 0; width: 42px; height: 26px; } 
.sprite-sb-extraSmall.sb_X { background-position: -1051px 0; height: 26px; } 
.sprite-sb-extraSmall.sb_Y { background-position: -1086px 0; } 
.sprite-sb-extraSmall.sb_L { background-position: -1121px 0; } 
.sprite-sb-extraSmall.sb_K { background-position: -1156px 0; width: 26px; } 
.sprite-sb-extraSmall.sb_Z { background-position: -1192px 0; width: 26px; } 

.sprite-rd-large { background: url('./assets/sprites/rd_large.png') no-repeat top left; width: 138px; height: 138px;  } 
.sprite-rd-large.rd_0 { background-position: -204px 0; width: 92px; } 
.sprite-rd-large.rd_1 { background-position: -102px 0; width: 92px; } 
.sprite-rd-large.rd_2 { background-position: -306px 0; width: 92px; } 
.sprite-rd-large.rd_3 { background-position: 0 0; width: 92px; } 
.sprite-rd-large.rd_4 { background-position: -510px 0; width: 92px; } 
.sprite-rd-large.rd_5 { background-position: -408px 0; width: 92px; } 
.sprite-rd-large.rd_6 { background-position: -612px 0; width: 92px; } 
.sprite-rd-large.rd_7 { background-position: -714px 0; width: 92px; } 
.sprite-rd-large.rd_8 { background-position: -816px 0; width: 92px; } 
.sprite-rd-large.rd_9 { background-position: -918px 0; width: 92px; } 
.sprite-rd-large.rd_A { background-position: -1020px 0; } 
.sprite-rd-large.rd_B { background-position: -1168px 0; width: 104px; } 
.sprite-rd-large.rd_C { background-position: -1282px 0; } 
.sprite-rd-large.rd_D { background-position: -1430px 0; } 
.sprite-rd-large.rd_E { background-position: -1726px 0; } 
.sprite-rd-large.rd_F { background-position: -1874px 0; } 
.sprite-rd-large.rd_G { background-position: -1578px 0; height: 207px; } 
.sprite-rd-large.rd_H { background-position: -2170px 0; height: 207px; } 
.sprite-rd-large.rd_I { background-position: -2466px 0; width: 69px; } 
.sprite-rd-large.rd_J { background-position: -2022px 0; } 
.sprite-rd-large.rd_K { background-position: -2318px 0; } 
.sprite-rd-large.rd_L { background-position: -2545px 0; width: 137px; } 
.sprite-rd-large.rd_M { background-position: -2692px 0; width: 207px; } 
.sprite-rd-large.rd_N { background-position: -2909px 0; } 
.sprite-rd-large.rd_O { background-position: -3057px 0; } 
.sprite-rd-large.rd_P { background-position: -3205px 0; width: 104px; } 
.sprite-rd-large.rd_Q { background-position: -3319px 0; height: 207px; } 
.sprite-rd-large.rd_R { background-position: -3467px 0; } 
.sprite-rd-large.rd_S { background-position: -3615px 0; width: 207px; } 
.sprite-rd-large.rd_T { background-position: -3832px 0; width: 207px; } 
.sprite-rd-large.rd_U { background-position: -4049px 0; } 
.sprite-rd-large.rd_V { background-position: -4197px 0; width: 190px; height: 144px; } 
.sprite-rd-large.rd_W { background-position: -4397px 0; width: 235px; height: 144px; } 
.sprite-rd-large.rd_X { background-position: -4642px 0; } 
.sprite-rd-large.rd_Y { background-position: -4790px 0; } 
.sprite-rd-large.rd_Z { background-position: -4938px 0; } 


.sprite-rd-medium { background: url('./assets/sprites/rd_medium.png') no-repeat top left; width: 75px; height: 75px;  } 
.sprite-rd-medium.rd_0 { background-position: 0 0; width: 50px; } 
.sprite-rd-medium.rd_1 { background-position: -60px 0; width: 50px; } 
.sprite-rd-medium.rd_2 { background-position: -120px 0; width: 50px; } 
.sprite-rd-medium.rd_3 { background-position: -180px 0; width: 50px; } 
.sprite-rd-medium.rd_4 { background-position: -240px 0; width: 50px; } 
.sprite-rd-medium.rd_5 { background-position: -300px 0; width: 50px; } 
.sprite-rd-medium.rd_6 { background-position: -360px 0; width: 50px; } 
.sprite-rd-medium.rd_7 { background-position: -420px 0; width: 51px; } 
.sprite-rd-medium.rd_8 { background-position: -481px 0; width: 50px; } 
.sprite-rd-medium.rd_9 { background-position: -541px 0; width: 50px; } 
.sprite-rd-medium.rd_A { background-position: -601px 0; } 
.sprite-rd-medium.rd_B { background-position: -686px 0; width: 57px; } 
.sprite-rd-medium.rd_C { background-position: -753px 0; height: 76px; } 
.sprite-rd-medium.rd_D { background-position: -1008px 0; height: 76px; } 
.sprite-rd-medium.rd_E { background-position: -923px 0; } 
.sprite-rd-medium.rd_F { background-position: -838px 0; height: 76px; } 
.sprite-rd-medium.rd_G { background-position: -1093px 0; height: 113px; } 
.sprite-rd-medium.rd_H { background-position: -1178px 0; height: 113px; } 
.sprite-rd-medium.rd_I { background-position: -1263px 0; width: 38px; } 
.sprite-rd-medium.rd_J { background-position: -1311px 0; } 
.sprite-rd-medium.rd_K { background-position: -1396px 0; } 
.sprite-rd-medium.rd_L { background-position: -1481px 0; } 
.sprite-rd-medium.rd_M { background-position: -1566px 0; width: 113px; } 
.sprite-rd-medium.rd_N { background-position: -1689px 0; } 
.sprite-rd-medium.rd_O { background-position: -1774px 0; } 
.sprite-rd-medium.rd_P { background-position: -1859px 0; width: 57px; } 
.sprite-rd-medium.rd_Q { background-position: -1926px 0; height: 113px; } 
.sprite-rd-medium.rd_R { background-position: -2011px 0; } 
.sprite-rd-medium.rd_S { background-position: -2096px 0; width: 113px; } 
.sprite-rd-medium.rd_U { background-position: -2219px 0; } 
.sprite-rd-medium.rd_T { background-position: -2304px 0; width: 113px; } 
.sprite-rd-medium.rd_V { background-position: -2427px 0; width: 104px; height: 78px; } 
.sprite-rd-medium.rd_W { background-position: -2541px 0; width: 128px; height: 78px; } 
.sprite-rd-medium.rd_Y { background-position: -2679px 0; } 
.sprite-rd-medium.rd_X { background-position: -2764px 0; } 
.sprite-rd-medium.rd_Z { background-position: -2849px 0; } 

.sprite-rd-small { background: url('./assets/sprites/rd_small.png') no-repeat top left; width: 41px; height: 41px;  } 
.sprite-rd-small.rd_0 { background-position: 0 0; width: 27px; } 
.sprite-rd-small.rd_1 { background-position: -37px 0; width: 27px; } 
.sprite-rd-small.rd_2 { background-position: -74px 0; width: 27px; } 
.sprite-rd-small.rd_3 { background-position: -111px 0; width: 27px; } 
.sprite-rd-small.rd_4 { background-position: -148px 0; width: 27px; } 
.sprite-rd-small.rd_6 { background-position: -185px 0; width: 27px; } 
.sprite-rd-small.rd_5 { background-position: -222px 0; width: 27px; } 
.sprite-rd-small.rd_7 { background-position: -259px 0; width: 28px; } 
.sprite-rd-small.rd_8 { background-position: -297px 0; width: 27px; } 
.sprite-rd-small.rd_9 { background-position: -334px 0; width: 27px; } 
.sprite-rd-small.rd_A { background-position: -371px 0; } 
.sprite-rd-small.rd_B { background-position: -422px 0; width: 31px; } 
.sprite-rd-small.rd_C { background-position: -463px 0; } 
.sprite-rd-small.rd_D { background-position: -514px 0; } 
.sprite-rd-small.rd_E { background-position: -565px 0; } 
.sprite-rd-small.rd_F { background-position: -616px 0; } 
.sprite-rd-small.rd_G { background-position: -667px 0; height: 62px; } 
.sprite-rd-small.rd_H { background-position: -718px 0; height: 62px; } 
.sprite-rd-small.rd_I { background-position: -769px 0; width: 21px; } 
.sprite-rd-small.rd_J { background-position: -800px 0; } 
.sprite-rd-small.rd_K { background-position: -851px 0; } 
.sprite-rd-small.rd_L { background-position: -902px 0; } 
.sprite-rd-small.rd_M { background-position: -953px 0; width: 61px; } 
.sprite-rd-small.rd_N { background-position: -1024px 0; } 
.sprite-rd-small.rd_O { background-position: -1075px 0; } 
.sprite-rd-small.rd_P { background-position: -1126px 0; width: 31px; } 
.sprite-rd-small.rd_Q { background-position: -1167px 0; height: 62px; } 
.sprite-rd-small.rd_R { background-position: -1218px 0; } 
.sprite-rd-small.rd_S { background-position: -1269px 0; width: 61px; } 
.sprite-rd-small.rd_T { background-position: -1340px 0; width: 61px; } 
.sprite-rd-small.rd_U { background-position: -1411px 0; } 
.sprite-rd-small.rd_V { background-position: -1462px 0; width: 56px; height: 43px; } 
.sprite-rd-small.rd_W { background-position: -1528px 0; width: 69px; height: 43px; } 
.sprite-rd-small.rd_X { background-position: -1607px 0; } 
.sprite-rd-small.rd_Z { background-position: -1658px 0; } 
.sprite-rd-small.rd_Y { background-position: -1709px 0; } 


.sprite-rd-extraSmall { background:  url('./assets/sprites/rd_extra_small.png')  no-repeat top left; width: 25px; height: 25px;  } 
.sprite-rd-extraSmall.rd_0 { background-position: 0 0; width: 18px; } 
.sprite-rd-extraSmall.rd_1 { background-position: -28px 0; width: 17px; } 
.sprite-rd-extraSmall.rd_2 { background-position: -55px 0; width: 17px; } 
.sprite-rd-extraSmall.rd_3 { background-position: -82px 0; width: 17px; } 
.sprite-rd-extraSmall.rd_4 { background-position: -109px 0; width: 17px; } 
.sprite-rd-extraSmall.rd_5 { background-position: -136px 0; width: 17px; } 
.sprite-rd-extraSmall.rd_6 { background-position: -163px 0; width: 17px; } 
.sprite-rd-extraSmall.rd_7 { background-position: -190px 0; width: 17px; } 
.sprite-rd-extraSmall.rd_8 { background-position: -217px 0; width: 17px; } 
.sprite-rd-extraSmall.rd_9 { background-position: -244px 0; width: 17px; } 
.sprite-rd-extraSmall.rd_A { background-position: -271px 0; } 
.sprite-rd-extraSmall.rd_B { background-position: -306px 0; width: 19px; } 
.sprite-rd-extraSmall.rd_C { background-position: -335px 0; width: 26px; } 
.sprite-rd-extraSmall.rd_D { background-position: -371px 0; } 
.sprite-rd-extraSmall.rd_E { background-position: -406px 0; } 
.sprite-rd-extraSmall.rd_F { background-position: -441px 0; width: 26px; } 
.sprite-rd-extraSmall.rd_G { background-position: -477px 0; height: 37px; } 
.sprite-rd-extraSmall.rd_H { background-position: -512px 0; width: 26px; height: 37px; } 
.sprite-rd-extraSmall.rd_I { background-position: -548px 0; width: 13px; } 
.sprite-rd-extraSmall.rd_J { background-position: -571px 0; } 
.sprite-rd-extraSmall.rd_K { background-position: -606px 0; width: 26px; } 
.sprite-rd-extraSmall.rd_L { background-position: -642px 0; } 
.sprite-rd-extraSmall.rd_M { background-position: -677px 0; width: 37px; } 
.sprite-rd-extraSmall.rd_N { background-position: -724px 0; width: 26px; } 
.sprite-rd-extraSmall.rd_O { background-position: -760px 0; } 
.sprite-rd-extraSmall.rd_P { background-position: -795px 0; width: 19px; } 
.sprite-rd-extraSmall.rd_Q { background-position: -824px 0; width: 26px; height: 37px; } 
.sprite-rd-extraSmall.rd_R { background-position: -860px 0; } 
.sprite-rd-extraSmall.rd_S { background-position: -895px 0; width: 37px; } 
.sprite-rd-extraSmall.rd_T { background-position: -942px 0; width: 38px; } 
.sprite-rd-extraSmall.rd_U { background-position: -990px 0; width: 26px; } 
.sprite-rd-extraSmall.rd_V { background-position: -1026px 0; width: 34px; height: 26px; } 
.sprite-rd-extraSmall.rd_W { background-position: -1070px 0; width: 42px; height: 26px; } 
.sprite-rd-extraSmall.rd_X { background-position: -1122px 0; height: 26px; } 
.sprite-rd-extraSmall.rd_Y { background-position: -1157px 0; } 
.sprite-rd-extraSmall.rd_Z { background-position: -1192px 0; width: 26px; } 


.sprite-or-large { background: url('./assets/sprites/or_large.png') no-repeat top left; width: 138px; height: 138px;  } 
.sprite-or-large.or_4 { background-position: 0 0; width: 92px; } 
.sprite-or-large.or_A { background-position: -102px 0; } 
.sprite-or-large.or_2 { background-position: -250px 0; width: 92px; } 
.sprite-or-large.or_7 { background-position: -352px 0; width: 92px; } 
.sprite-or-large.or_3 { background-position: -454px 0; width: 92px; } 
.sprite-or-large.or_8 { background-position: -556px 0; width: 92px; } 
.sprite-or-large.or_B { background-position: -658px 0; width: 104px; } 
.sprite-or-large.or_D { background-position: -772px 0; } 
.sprite-or-large.or_0 { background-position: -920px 0; width: 92px; } 
.sprite-or-large.or_C { background-position: -1022px 0; } 
.sprite-or-large.or_1 { background-position: -1170px 0; width: 92px; } 
.sprite-or-large.or_5 { background-position: -1272px 0; width: 92px; } 
.sprite-or-large.or_6 { background-position: -1374px 0; width: 92px; } 
.sprite-or-large.or_F { background-position: -1476px 0; } 
.sprite-or-large.or_H { background-position: -1624px 0; height: 207px; } 
.sprite-or-large.or_G { background-position: -1772px 0; height: 207px; } 
.sprite-or-large.or_J { background-position: -1920px 0; } 
.sprite-or-large.or_K { background-position: -2068px 0; } 
.sprite-or-large.or_N { background-position: -2216px 0; } 
.sprite-or-large.or_P { background-position: -2364px 0; width: 104px; } 
.sprite-or-large.or_Q { background-position: -2478px 0; height: 207px; } 
.sprite-or-large.or_U { background-position: -2626px 0; } 
.sprite-or-large.or_L { background-position: -2774px 0; width: 137px; } 
.sprite-or-large.or_O { background-position: -2921px 0; } 
.sprite-or-large.or_M { background-position: -3069px 0; width: 207px; } 
.sprite-or-large.or_R { background-position: -3286px 0; } 
.sprite-or-large.or_T { background-position: -3434px 0; width: 207px; } 
.sprite-or-large.or_9 { background-position: -3651px 0; width: 92px; } 
.sprite-or-large.or_Y { background-position: -3753px 0; } 
.sprite-or-large.or_X { background-position: -3901px 0; } 
.sprite-or-large.or_W { background-position: -4049px 0; width: 235px; height: 144px; } 
.sprite-or-large.or_Z { background-position: -4294px 0; } 
.sprite-or-large.or_E { background-position: -4442px 0; } 
.sprite-or-large.or_V { background-position: -4590px 0; width: 190px; height: 144px; } 
.sprite-or-large.or_I { background-position: -4790px 0; width: 69px; } 
.sprite-or-large.or_S { background-position: -4869px 0; width: 207px; } 


.sprite-or-medium { background: url('./assets/sprites/or_medium.png') no-repeat top left; width: 75px; height: 75px;  } 
.sprite-or-medium.or_5 { background-position: 0 0; width: 50px; } 
.sprite-or-medium.or_0 { background-position: -60px 0; width: 50px; } 
.sprite-or-medium.or_1 { background-position: -120px 0; width: 50px; } 
.sprite-or-medium.or_4 { background-position: -180px 0; width: 50px; } 
.sprite-or-medium.or_6 { background-position: -240px 0; width: 50px; } 
.sprite-or-medium.or_3 { background-position: -300px 0; width: 50px; } 
.sprite-or-medium.or_2 { background-position: -360px 0; width: 50px; } 
.sprite-or-medium.or_7 { background-position: -420px 0; width: 51px; } 
.sprite-or-medium.or_8 { background-position: -481px 0; width: 50px; } 
.sprite-or-medium.or_F { background-position: -541px 0; height: 76px; } 
.sprite-or-medium.or_L { background-position: -626px 0; } 
.sprite-or-medium.or_N { background-position: -711px 0; } 
.sprite-or-medium.or_E { background-position: -796px 0; } 
.sprite-or-medium.or_9 { background-position: -881px 0; width: 50px; } 
.sprite-or-medium.or_A { background-position: -941px 0; } 
.sprite-or-medium.or_P { background-position: -1026px 0; width: 57px; } 
.sprite-or-medium.or_C { background-position: -1093px 0; height: 76px; } 
.sprite-or-medium.or_D { background-position: -1178px 0; height: 76px; } 
.sprite-or-medium.or_B { background-position: -1263px 0; width: 57px; } 
.sprite-or-medium.or_J { background-position: -1330px 0; } 
.sprite-or-medium.or_O { background-position: -1415px 0; } 
.sprite-or-medium.or_H { background-position: -1500px 0; height: 113px; } 
.sprite-or-medium.or_K { background-position: -1585px 0; } 
.sprite-or-medium.or_M { background-position: -1670px 0; width: 113px; } 
.sprite-or-medium.or_S { background-position: -1793px 0; width: 113px; } 
.sprite-or-medium.or_R { background-position: -1916px 0; } 
.sprite-or-medium.or_Q { background-position: -2001px 0; height: 113px; } 
.sprite-or-medium.or_X { background-position: -2086px 0; } 
.sprite-or-medium.or_W { background-position: -2171px 0; width: 128px; height: 78px; } 
.sprite-or-medium.or_I { background-position: -2309px 0; width: 38px; } 
.sprite-or-medium.or_Y { background-position: -2357px 0; } 
.sprite-or-medium.or_Z { background-position: -2442px 0; } 
.sprite-or-medium.or_T { background-position: -2527px 0; width: 113px; } 
.sprite-or-medium.or_U { background-position: -2650px 0; } 
.sprite-or-medium.or_V { background-position: -2735px 0; width: 104px; height: 78px; } 
.sprite-or-medium.or_G { background-position: -2849px 0; height: 113px; } 

.sprite-or-small { background: url('./assets/sprites/or_small.png') no-repeat top left; width: 41px; height: 41px;  } 
.sprite-or-small.or_0 { background-position: 0 0; width: 27px; } 
.sprite-or-small.or_1 { background-position: -37px 0; width: 27px; } 
.sprite-or-small.or_2 { background-position: -74px 0; width: 27px; } 
.sprite-or-small.or_4 { background-position: -111px 0; width: 27px; } 
.sprite-or-small.or_5 { background-position: -148px 0; width: 27px; } 
.sprite-or-small.or_7 { background-position: -185px 0; width: 28px; } 
.sprite-or-small.or_8 { background-position: -223px 0; width: 27px; } 
.sprite-or-small.or_9 { background-position: -260px 0; width: 27px; } 
.sprite-or-small.or_A { background-position: -297px 0; } 
.sprite-or-small.or_3 { background-position: -348px 0; width: 27px; } 
.sprite-or-small.or_B { background-position: -385px 0; width: 31px; } 
.sprite-or-small.or_D { background-position: -426px 0; } 
.sprite-or-small.or_E { background-position: -477px 0; } 
.sprite-or-small.or_F { background-position: -528px 0; } 
.sprite-or-small.or_C { background-position: -579px 0; } 
.sprite-or-small.or_G { background-position: -630px 0; height: 62px; } 
.sprite-or-small.or_I { background-position: -681px 0; width: 21px; } 
.sprite-or-small.or_H { background-position: -712px 0; height: 62px; } 
.sprite-or-small.or_6 { background-position: -763px 0; width: 27px; } 
.sprite-or-small.or_L { background-position: -800px 0; } 
.sprite-or-small.or_K { background-position: -851px 0; } 
.sprite-or-small.or_J { background-position: -902px 0; } 
.sprite-or-small.or_M { background-position: -953px 0; width: 61px; } 
.sprite-or-small.or_O { background-position: -1024px 0; } 
.sprite-or-small.or_N { background-position: -1075px 0; } 
.sprite-or-small.or_P { background-position: -1126px 0; width: 31px; } 
.sprite-or-small.or_R { background-position: -1167px 0; } 
.sprite-or-small.or_Q { background-position: -1218px 0; height: 62px; } 
.sprite-or-small.or_S { background-position: -1269px 0; width: 61px; } 
.sprite-or-small.or_U { background-position: -1340px 0; } 
.sprite-or-small.or_V { background-position: -1391px 0; width: 56px; height: 43px; } 
.sprite-or-small.or_X { background-position: -1457px 0; } 
.sprite-or-small.or_Y { background-position: -1508px 0; } 
.sprite-or-small.or_Z { background-position: -1559px 0; } 
.sprite-or-small.or_W { background-position: -1610px 0; width: 69px; height: 43px; } 
.sprite-or-small.or_T { background-position: -1689px 0; width: 61px; } 

.sprite-or-extraSmall { background: url('./assets/sprites/or_extra_small.png') no-repeat top left; width: 25px; height: 25px;  } 
.sprite-or-extraSmall.or_0 { background-position: 0 0; width: 18px; } 
.sprite-or-extraSmall.or_1 { background-position: -28px 0; width: 17px; } 
.sprite-or-extraSmall.or_2 { background-position: -55px 0; width: 17px; } 
.sprite-or-extraSmall.or_3 { background-position: -82px 0; width: 17px; } 
.sprite-or-extraSmall.or_4 { background-position: -109px 0; width: 17px; } 
.sprite-or-extraSmall.or_5 { background-position: -136px 0; width: 17px; } 
.sprite-or-extraSmall.or_6 { background-position: -163px 0; width: 17px; } 
.sprite-or-extraSmall.or_7 { background-position: -190px 0; width: 17px; } 
.sprite-or-extraSmall.or_8 { background-position: -217px 0; width: 17px; } 
.sprite-or-extraSmall.or_9 { background-position: -244px 0; width: 17px; } 
.sprite-or-extraSmall.or_A { background-position: -271px 0; } 
.sprite-or-extraSmall.or_B { background-position: -306px 0; width: 19px; } 
.sprite-or-extraSmall.or_C { background-position: -335px 0; width: 26px; } 
.sprite-or-extraSmall.or_D { background-position: -371px 0; } 
.sprite-or-extraSmall.or_E { background-position: -406px 0; } 
.sprite-or-extraSmall.or_F { background-position: -441px 0; width: 26px; } 
.sprite-or-extraSmall.or_G { background-position: -477px 0; height: 37px; } 
.sprite-or-extraSmall.or_H { background-position: -512px 0; width: 26px; height: 37px; } 
.sprite-or-extraSmall.or_I { background-position: -548px 0; width: 13px; } 
.sprite-or-extraSmall.or_J { background-position: -571px 0; } 
.sprite-or-extraSmall.or_K { background-position: -606px 0; width: 26px; } 
.sprite-or-extraSmall.or_L { background-position: -642px 0; } 
.sprite-or-extraSmall.or_M { background-position: -677px 0; width: 37px; } 
.sprite-or-extraSmall.or_N { background-position: -724px 0; width: 26px; } 
.sprite-or-extraSmall.or_O { background-position: -760px 0; } 
.sprite-or-extraSmall.or_P { background-position: -795px 0; width: 19px; } 
.sprite-or-extraSmall.or_Q { background-position: -824px 0; width: 26px; height: 37px; } 
.sprite-or-extraSmall.or_S { background-position: -860px 0; width: 37px; } 
.sprite-or-extraSmall.or_T { background-position: -907px 0; width: 38px; } 
.sprite-or-extraSmall.or_U { background-position: -955px 0; width: 26px; } 
.sprite-or-extraSmall.or_V { background-position: -991px 0; width: 34px; height: 26px; } 
.sprite-or-extraSmall.or_W { background-position: -1035px 0; width: 42px; height: 26px; } 
.sprite-or-extraSmall.or_X { background-position: -1087px 0; height: 26px; } 
.sprite-or-extraSmall.or_Z { background-position: -1122px 0; width: 26px; } 
.sprite-or-extraSmall.or_Y { background-position: -1158px 0; } 
.sprite-or-extraSmall.or_R { background-position: -1193px 0; } 

.sprite-cy-large { background: url('./assets/sprites/cy_large.png') no-repeat top left; width: 138px; height: 138px;  } 
.sprite-cy-large.cy_0 { background-position: 0 0; width: 92px; } 
.sprite-cy-large.cy_1 { background-position: -102px 0; width: 92px; } 
.sprite-cy-large.cy_2 { background-position: -204px 0; width: 92px; } 
.sprite-cy-large.cy_3 { background-position: -306px 0; width: 92px; } 
.sprite-cy-large.cy_4 { background-position: -408px 0; width: 92px; } 
.sprite-cy-large.cy_5 { background-position: -510px 0; width: 92px; } 
.sprite-cy-large.cy_6 { background-position: -612px 0; width: 92px; } 
.sprite-cy-large.cy_7 { background-position: -714px 0; width: 92px; } 
.sprite-cy-large.cy_8 { background-position: -816px 0; width: 92px; } 
.sprite-cy-large.cy_9 { background-position: -918px 0; width: 92px; } 
.sprite-cy-large.cy_A { background-position: -1020px 0; } 
.sprite-cy-large.cy_B { background-position: -1168px 0; width: 104px; } 
.sprite-cy-large.cy_C { background-position: -1282px 0; } 
.sprite-cy-large.cy_E { background-position: -1430px 0; } 
.sprite-cy-large.cy_D { background-position: -1578px 0; } 
.sprite-cy-large.cy_F { background-position: -1726px 0; } 
.sprite-cy-large.cy_G { background-position: -1874px 0; height: 207px; } 
.sprite-cy-large.cy_H { background-position: -2022px 0; height: 207px; } 
.sprite-cy-large.cy_I { background-position: -2170px 0; width: 69px; } 
.sprite-cy-large.cy_J { background-position: -2249px 0; } 
.sprite-cy-large.cy_K { background-position: -2397px 0; } 
.sprite-cy-large.cy_L { background-position: -2545px 0; width: 137px; } 
.sprite-cy-large.cy_M { background-position: -2692px 0; width: 207px; } 
.sprite-cy-large.cy_N { background-position: -2909px 0; } 
.sprite-cy-large.cy_O { background-position: -3057px 0; } 
.sprite-cy-large.cy_Q { background-position: -3205px 0; height: 207px; } 
.sprite-cy-large.cy_P { background-position: -3353px 0; width: 104px; } 
.sprite-cy-large.cy_R { background-position: -3467px 0; } 
.sprite-cy-large.cy_S { background-position: -3615px 0; width: 207px; } 
.sprite-cy-large.cy_T { background-position: -3832px 0; width: 207px; } 
.sprite-cy-large.cy_U { background-position: -4049px 0; } 
.sprite-cy-large.cy_V { background-position: -4197px 0; width: 190px; height: 144px; } 
.sprite-cy-large.cy_W { background-position: -4397px 0; width: 235px; height: 144px; } 
.sprite-cy-large.cy_X { background-position: -4642px 0; } 
.sprite-cy-large.cy_Y { background-position: -4790px 0; } 
.sprite-cy-large.cy_Z { background-position: -4938px 0; } 

.sprite-cy-medium { background: url('./assets/sprites/cy_medium.png') no-repeat top left; width: 75px; height: 75px;  } 
.sprite-cy-medium.cy_0 { background-position: 0 0; width: 50px; } 
.sprite-cy-medium.cy_2 { background-position: -60px 0; width: 50px; } 
.sprite-cy-medium.cy_4 { background-position: -120px 0; width: 50px; } 
.sprite-cy-medium.cy_3 { background-position: -180px 0; width: 50px; } 
.sprite-cy-medium.cy_5 { background-position: -240px 0; width: 50px; } 
.sprite-cy-medium.cy_6 { background-position: -300px 0; width: 50px; } 
.sprite-cy-medium.cy_7 { background-position: -360px 0; width: 51px; } 
.sprite-cy-medium.cy_8 { background-position: -421px 0; width: 50px; } 
.sprite-cy-medium.cy_9 { background-position: -481px 0; width: 50px; } 
.sprite-cy-medium.cy_A { background-position: -541px 0; } 
.sprite-cy-medium.cy_B { background-position: -626px 0; width: 57px; } 
.sprite-cy-medium.cy_C { background-position: -693px 0; height: 76px; } 
.sprite-cy-medium.cy_D { background-position: -778px 0; height: 76px; } 
.sprite-cy-medium.cy_E { background-position: -863px 0; } 
.sprite-cy-medium.cy_F { background-position: -948px 0; height: 76px; } 
.sprite-cy-medium.cy_G { background-position: -1033px 0; height: 113px; } 
.sprite-cy-medium.cy_H { background-position: -1118px 0; height: 113px; } 
.sprite-cy-medium.cy_I { background-position: -1203px 0; width: 38px; } 
.sprite-cy-medium.cy_1 { background-position: -1251px 0; width: 50px; } 
.sprite-cy-medium.cy_J { background-position: -1311px 0; } 
.sprite-cy-medium.cy_K { background-position: -1396px 0; } 
.sprite-cy-medium.cy_L { background-position: -1481px 0; } 
.sprite-cy-medium.cy_M { background-position: -1566px 0; width: 113px; } 
.sprite-cy-medium.cy_N { background-position: -1689px 0; } 
.sprite-cy-medium.cy_O { background-position: -1774px 0; } 
.sprite-cy-medium.cy_P { background-position: -1859px 0; width: 57px; } 
.sprite-cy-medium.cy_Q { background-position: -1926px 0; height: 113px; } 
.sprite-cy-medium.cy_R { background-position: -2011px 0; } 
.sprite-cy-medium.cy_S { background-position: -2096px 0; width: 113px; } 
.sprite-cy-medium.cy_T { background-position: -2219px 0; width: 113px; } 
.sprite-cy-medium.cy_U { background-position: -2342px 0; } 
.sprite-cy-medium.cy_V { background-position: -2427px 0; width: 104px; height: 78px; } 
.sprite-cy-medium.cy_W { background-position: -2541px 0; width: 128px; height: 78px; } 
.sprite-cy-medium.cy_X { background-position: -2679px 0; } 
.sprite-cy-medium.cy_Y { background-position: -2764px 0; } 
.sprite-cy-medium.cy_Z { background-position: -2849px 0; } 

.sprite-cy-small { background: url('./assets/sprites/cy_small.png') no-repeat top left; width: 41px; height: 41px;  } 
.sprite-cy-small.cy_0 { background-position: 0 0; width: 27px; } 
.sprite-cy-small.cy_2 { background-position: -37px 0; width: 27px; } 
.sprite-cy-small.cy_3 { background-position: -74px 0; width: 27px; } 
.sprite-cy-small.cy_4 { background-position: -111px 0; width: 27px; } 
.sprite-cy-small.cy_5 { background-position: -148px 0; width: 27px; } 
.sprite-cy-small.cy_6 { background-position: -185px 0; width: 27px; } 
.sprite-cy-small.cy_7 { background-position: -222px 0; width: 28px; } 
.sprite-cy-small.cy_9 { background-position: -260px 0; width: 27px; } 
.sprite-cy-small.cy_1 { background-position: -297px 0; width: 27px; } 
.sprite-cy-small.cy_B { background-position: -334px 0; width: 31px; } 
.sprite-cy-small.cy_C { background-position: -375px 0; } 
.sprite-cy-small.cy_D { background-position: -426px 0; } 
.sprite-cy-small.cy_E { background-position: -477px 0; } 
.sprite-cy-small.cy_F { background-position: -528px 0; } 
.sprite-cy-small.cy_G { background-position: -579px 0; height: 62px; } 
.sprite-cy-small.cy_H { background-position: -630px 0; height: 62px; } 
.sprite-cy-small.cy_I { background-position: -681px 0; width: 21px; } 
.sprite-cy-small.cy_A { background-position: -712px 0; } 
.sprite-cy-small.cy_8 { background-position: -763px 0; width: 27px; } 
.sprite-cy-small.cy_J { background-position: -800px 0; } 
.sprite-cy-small.cy_K { background-position: -851px 0; } 
.sprite-cy-small.cy_L { background-position: -902px 0; } 
.sprite-cy-small.cy_M { background-position: -953px 0; width: 61px; } 
.sprite-cy-small.cy_N { background-position: -1024px 0; } 
.sprite-cy-small.cy_O { background-position: -1075px 0; } 
.sprite-cy-small.cy_P { background-position: -1126px 0; width: 31px; } 
.sprite-cy-small.cy_Q { background-position: -1167px 0; height: 62px; } 
.sprite-cy-small.cy_R { background-position: -1218px 0; } 
.sprite-cy-small.cy_S { background-position: -1269px 0; width: 61px; } 
.sprite-cy-small.cy_T { background-position: -1340px 0; width: 61px; } 
.sprite-cy-small.cy_U { background-position: -1411px 0; } 
.sprite-cy-small.cy_V { background-position: -1462px 0; width: 56px; height: 43px; } 
.sprite-cy-small.cy_W { background-position: -1528px 0; width: 69px; height: 43px; } 
.sprite-cy-small.cy_X { background-position: -1607px 0; } 
.sprite-cy-small.cy_Y { background-position: -1658px 0; } 
.sprite-cy-small.cy_Z { background-position: -1709px 0; } 

.sprite-cy-extraSmall { background: url('./assets/sprites/cy_extra_small.png') no-repeat top left; width: 25px; height: 25px;  } 
.sprite-cy-extraSmall.cy_0 { background-position: 0 0; width: 18px; } 
.sprite-cy-extraSmall.cy_1 { background-position: -28px 0; width: 17px; } 
.sprite-cy-extraSmall.cy_2 { background-position: -55px 0; width: 17px; } 
.sprite-cy-extraSmall.cy_3 { background-position: -82px 0; width: 17px; } 
.sprite-cy-extraSmall.cy_4 { background-position: -109px 0; width: 17px; } 
.sprite-cy-extraSmall.cy_5 { background-position: -136px 0; width: 17px; } 
.sprite-cy-extraSmall.cy_6 { background-position: -163px 0; width: 17px; } 
.sprite-cy-extraSmall.cy_7 { background-position: -190px 0; width: 17px; } 
.sprite-cy-extraSmall.cy_8 { background-position: -217px 0; width: 17px; } 
.sprite-cy-extraSmall.cy_9 { background-position: -244px 0; width: 17px; } 
.sprite-cy-extraSmall.cy_A { background-position: -271px 0; } 
.sprite-cy-extraSmall.cy_B { background-position: -306px 0; width: 19px; } 
.sprite-cy-extraSmall.cy_C { background-position: -335px 0; width: 26px; } 
.sprite-cy-extraSmall.cy_D { background-position: -371px 0; } 
.sprite-cy-extraSmall.cy_E { background-position: -406px 0; } 
.sprite-cy-extraSmall.cy_F { background-position: -441px 0; width: 26px; } 
.sprite-cy-extraSmall.cy_G { background-position: -477px 0; height: 37px; } 
.sprite-cy-extraSmall.cy_H { background-position: -512px 0; width: 26px; height: 37px; } 
.sprite-cy-extraSmall.cy_I { background-position: -548px 0; width: 13px; } 
.sprite-cy-extraSmall.cy_J { background-position: -571px 0; } 
.sprite-cy-extraSmall.cy_K { background-position: -606px 0; width: 26px; } 
.sprite-cy-extraSmall.cy_L { background-position: -642px 0; } 
.sprite-cy-extraSmall.cy_M { background-position: -677px 0; width: 37px; } 
.sprite-cy-extraSmall.cy_O { background-position: -724px 0; } 
.sprite-cy-extraSmall.cy_P { background-position: -759px 0; width: 19px; } 
.sprite-cy-extraSmall.cy_Q { background-position: -788px 0; width: 26px; height: 37px; } 
.sprite-cy-extraSmall.cy_N { background-position: -824px 0; width: 26px; } 
.sprite-cy-extraSmall.cy_R { background-position: -860px 0; } 
.sprite-cy-extraSmall.cy_T { background-position: -895px 0; width: 38px; } 
.sprite-cy-extraSmall.cy_S { background-position: -943px 0; width: 37px; } 
.sprite-cy-extraSmall.cy_V { background-position: -990px 0; width: 34px; height: 26px; } 
.sprite-cy-extraSmall.cy_W { background-position: -1034px 0; width: 42px; height: 26px; } 
.sprite-cy-extraSmall.cy_U { background-position: -1086px 0; width: 26px; } 
.sprite-cy-extraSmall.cy_Y { background-position: -1122px 0; } 
.sprite-cy-extraSmall.cy_X { background-position: -1157px 0; height: 26px; } 
.sprite-cy-extraSmall.cy_Z { background-position: -1192px 0; width: 26px; } 